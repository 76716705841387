@import "/src/styles/breakpoints.scss";
@import "/src/styles/variables.scss";

.page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 115px;
  text-align: center;
  .header {
    margin: 50px 0 40px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @include medium {
      margin: 70px 0 40px 0;
    }
  }
  .pageTitle {
    margin-top: 5px;
  }

  .storeImageWrapper {
    position: relative;
    width: 100%;
    aspect-ratio: 16 /9;
  }

  .storeImage {
    object-fit: cover;
  }

  .intro {
    max-width: $body-text-max-width;
    padding: 40px 0;
    text-align: center;
  }

  .storeDetails {
    display: grid;

    width: 100%;
    align-items: stretch;
    gap: 20px;
    padding: 0 0 40px 0;
    @include medium {
      display: flex;
      flex-wrap: wrap;
      padding: 40px 0;
    }
    .openingHours {
      // flex: 3;
      flex: 2;
    }
    .address {
      flex: 1;
      // flex: 2;
    }
  }

  .storeDetail {
    padding: 0;
    .label {
      color: $color-secondary;
      font-size: 14px;
      line-height: 19px;
      font-weight: bold;
    }
    .storeDetailContent {
      border-bottom: 1px solid $color-neutral-2;
      border-top: 1px solid $color-neutral-2;
      padding: 20px 0;
      font-size: 12px;
    }
  }

  .openingHoursContent {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
  }
  .addressContent {
    display: flex;
    align-items: center;
  }

  .directionsButton {
    margin-left: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $color-white;
    color: $color-secondary;
    font-size: 10px;
    font-weight: bold;
    font-family: $font-family-gibson;
    text-transform: uppercase;
    letter-spacing: 0.2px;
    padding: 6px 10px;
    border-radius: 25px;
  }

  .directionsButtonIcon {
    font-size: 10px;
    margin-right: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
