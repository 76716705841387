@import "/src/styles/breakpoints.scss";
@import "/src/styles/variables.scss";

.basicPage {
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  // justify-content: center;
  // text-align: center;
  margin: 0 auto;

  // padding-bottom: 80px;
  padding-bottom: 104px;
  max-width: 550px;
  .headingArea {
    text-align: center;
    margin-top: 50px;
    margin-bottom: 40px;
    @include medium {
      margin-top: 70px;
    }
  }

  ul,
  ol {
    margin-bottom: 24px;
    color: $text-color;
    font: normal normal $font-weight-gibson-regular 16px/22px
      $font-family-gibson;
    letter-spacing: 0px;
    text-align: left;
    @include medium {
      font-size: 18px;
      line-height: 24px;
    }
  }
  ol {
    list-style: auto;
    text-align: start;
    margin-left: 18px;
  }
  li {
    list-style: disc;
    text-align: start;
    // margin-left: 18px;
    margin: 5px 0 5px 18px;
  }
}
