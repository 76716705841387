@import "/src/styles/breakpoints.scss";
@import "/src/styles/variables.scss";

$header-height: 54px;

.orderNowButton {
  width: 100%;
  height: $header-height;
  color: $color-white;
  font-size: 14px;
  font-family: $font-family-gibson;
  font-weight: $font-weight-gibson-bold;
  line-height: normal;
  letter-spacing: 0.28px;
  text-transform: uppercase;
  text-align: left;
  background: $color-secondary;
  color: $color-white;
  opacity: 1;
  padding: 0 20px;
  border-bottom: 2px solid rgba($color-neutral-1, 0.5);
}

.contents {
  // max-height: calc(100vh - $header-height);
  max-height: calc(100% - $header-height);
  overflow: auto;
}
