@import "/src/styles/breakpoints.scss";
@import "/src/styles/variables.scss";

$speed: 0.3s;

$item-height: 54px;

:export {
  itemHeight: $item-height;
}

.locationGroupsContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.groupButton {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 900;
  line-height: normal;
  letter-spacing: 0.28px;
  text-transform: uppercase;
  width: 100%;
  padding: 0 20px;
  height: $item-height;
  background-color: $color-secondary;
  color: $color-primary;
  border-bottom: 2px solid rgba($color-neutral-1, 0.5);
}

.groupButtonActive {
  color: $color-white;
}

.groupIcon {
  height: 10px;
  width: 10px;
  transition: transform $speed ease;
}

.groupIconActive {
  transform: rotate(180deg);
  transition: transform $speed ease;
}

.groupTitle {
  padding: 20px;
  cursor: pointer;
  font-size: 1.2rem;
  font-weight: bold;

  border: none;
  width: 100%;
  text-align: left;
  color: $color-primary;
  border-bottom: 2px solid rgba($color-neutral-1, 0.5);
  transition: all 1s ease;
  text-transform: uppercase;
  text-align: left;
  font: normal normal $font-weight-gibson-bold 12px/19px $font-family-gibson;
  letter-spacing: 0.24px;
}

.locationList {
  overflow: hidden;
  transition: height $speed ease-out, opacity $speed ease-out;
  opacity: 0;
  li {
    background-color: $color-white;
    color: $color-secondary;
    height: $item-height;
    border-bottom: 2px solid rgba($color-neutral-1, 0.5);

    font-size: 14px;
    font-family: $font-family-gibson;
    font-weight: 600;
    display: flex;
    align-items: center;
    height: $item-height;
  }
  a {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    padding: 0 20px;
  }
  .locationItem {
    text-transform: capitalize;
  }
}

.expanded {
  opacity: 1;
  color: $color-white;
  .groupTitle {
    color: $color-white;
  }
}
