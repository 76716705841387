@import "/src/styles/breakpoints.scss";
@import "/src/styles/variables.scss";

.container {
  max-width: $content-max-width;
  margin-left: auto;
  margin-right: auto;
  padding: 0 10px;
}

.normal {
  max-width: $content-max-width;
}

$extra-width: 40px;
.extended {
  max-width: calc($content-max-width + $extra-width);
  padding: 0;
}

.stretch {
  max-width: unset;
  padding: 0;
}
