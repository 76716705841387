@import "/src/styles/breakpoints.scss";
@import "/src/styles/variables.scss";

.section {
  text-align: center;
  padding: 40px 0 180px 0;
  @include medium {
    padding: 80px 0 180px 0;
  }
}

.carouselSection {
  position: relative;
}
