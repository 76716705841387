@import "/src/styles/breakpoints.scss";
@import "/src/styles/variables.scss";

.page {
  .pageContainer {
    max-width: 550px;
    padding-bottom: 100px;
  }

  .pageTitle {
    margin-top: 50px;
    margin-bottom: 40px;
    @include medium {
      margin-top: 70px;
    }
  }
}
