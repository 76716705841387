@import "/src/styles/breakpoints.scss";
@import "/src/styles/variables.scss";
@import "/src/components/button/Button.module.scss";

.container {
  position: relative;
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
}

.line {
  position: absolute;
  top: calc(50% + $elevated-button-offset);
  height: 2px;
  width: 100%;
  background: $color-secondary;
}

.button {
  z-index: 1;
}
