@import "/src/styles/breakpoints.scss";
@import "/src/styles/variables.scss";

.section {
  padding-bottom: 80px;
}

.typographyContainer {
  max-width: 550px;
}

.page {
  .pageTitle {
    margin-top: 50px;
    margin-bottom: 40px;
    @include medium {
      margin-top: 70px;
    }
  }
  .headerImageContainer {
    width: 100%;
    aspect-ratio: 16 / 9;
    margin-bottom: 40px;
  }
  .headerImage {
    width: 100%;
  }
}

.video {
  margin-bottom: 40px;
}
