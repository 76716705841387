@import "/src/styles/breakpoints.scss";
@import "/src/styles/variables.scss";

$speed: 0.6s;

.drawer {
  position: fixed;
  top: 0;
  left: -100%;
  width: 300px;
  height: 100%;
  // height: 100vh;
  background-color: $color-secondary;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.3);
  transition: left $speed ease;
  z-index: $z-index-modal;

  &.open {
    left: 0;
  }

  @include medium {
    display: none;
  }

  .closeButton {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    right: -15px;
    top: 12px;
    height: 30px;
    width: 30px;
    background-color: $color-neutral-1;
    border-radius: $radius-full;
    border: 2px solid $color-white;
    color: $color-secondary;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  // height: 100vh;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(0px);
  opacity: 0;
  transition: backdrop-filter $speed ease, opacity $speed ease;
  z-index: $z-index-modal-backdrop;
  pointer-events: none;

  &.open {
    opacity: 1;
    backdrop-filter: blur(10px);
    pointer-events: all;
  }

  @include medium {
    display: none;
  }
}

.contents {
  height: 100%;
}
