@import "/src/styles/variables.scss";

.section {
}

.dark {
  background-color: $color-black;
}
.neutral {
  background-color: $color-neutral-1;
}
