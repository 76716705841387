@import "/src/styles/breakpoints.scss";
@import "/src/styles/variables.scss";

.main {
  transition: opacity 0.3s linear;
  min-height: 100vh;
}

.dark {
  background-color: $color-black;
}

.neutral {
  background-color: $color-neutral-1;
}

.fadeInPage {
  opacity: 0;
}

.fadeInPageLoaded {
  opacity: 1;
}

.avoidHeader {
  padding-top: var(--appbar-height);
}
