@import "/src/styles/breakpoints.scss";
@import "/src/styles/variables.scss";

.container {
  position: relative;
  display: inline-block;
}

.orderNowButton {
  padding: 0 4px 0 4px;
  @include tiny {
    padding: 0 $side-padding 0 $side-padding;
  }
}
