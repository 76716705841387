@import "/src/styles/breakpoints.scss";
@import "/src/styles/variables.scss";

.navbar {
  flex: 1;
  display: flex;
}

.navLinks {
  display: flex;
  justify-content: flex-end;
  flex: 1;
  gap: 8px;
  @include tiny {
    display: flex;
    border: none;
    gap: 16px;
  }
  @include medium {
    display: flex;
    border: none;
    gap: 40px;
  }
}

.link {
  position: relative;
  font: normal normal bold 12px/19px $font-family-gibson;
  letter-spacing: 0.24px;
  color: $color-primary;
  opacity: 1;
  color: $color-primary;
  text-decoration: none;
  border-bottom: 3px solid transparent;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
  text-transform: uppercase;
  padding: 4px 0;
  &:hover {
    color: $color-white;
  }
  @include medium {
    font: normal normal bold 14px/19px $font-family-gibson;
    letter-spacing: 0.28px;
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      height: 2px;
      width: 0;
      background-color: $color-white;
      transition: width 0.3s ease;
    }
    &:hover::after {
      width: 100%;
    }
  }
}

.activeLink {
  color: $color-white;
  &:hover::after {
    width: 0;
  }
}
