@import "/src/styles/breakpoints.scss";
@import "/src/styles/variables.scss";

.imageContainer {
  position: relative;
  height: 100%;
  width: 100%;
}

.image {
  position: absolute;
  left: 0;
  top: 0;
  overflow: hidden;
  display: block;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  // object-position: top;
  transition: opacity 1s ease;
  opacity: 0;
}

.active {
  opacity: 1;
}
