.parallax {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  transform: translateY(0);
  transition: transform 0.1s linear;
}

.foreground {
  position: relative;
  z-index: 2;
  padding: 20px;
  text-align: center;
  width: 100%;
  height: 100%;
}
